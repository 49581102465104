import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { ButtonPros } from 'core/types';
import { IconCloud, IconPrinter, IconTrash } from 'icons';
import { InputSearch } from 'components/inputSearch';
import { formatDateToBack, getWeekDays, isNewImage } from 'core/utils';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { ItemFilter, NumberFiler } from 'features/numberFiler';
import { ItemTabs, Tabs } from 'components/tabs';
import { TabsContextProvider, useTabsContext } from './context';
import { GenerateDateWeek } from 'features/generateDateWeek';
import { workplaceSelector, workplaceSlice } from './workplaceSlice';
import { AboutPlace } from './tabsContent/aboutPlace';
//import { Service } from './tabsContent/service';
import { PaginationBack } from 'features/paginationBack';
import { Row } from './table/row';
import { ModalAnt } from 'components/modalAnt';
import { PopUpAnt } from 'components/popupAnt';
import { DefaultButton, IconButton, WhiteButton } from 'components/buttons';
import { ItemWorkplace } from './itemWorkspace';
import { HeaderTable } from './table/headerTable';
import debounce from 'lodash/debounce';
import config from 'api/config';

export const dataFilter = [
  { id: 0, value: 10 },
  { id: 1, value: 20 },
  { id: 2, value: 40 },
  { id: 3, value: 80 },
];

const WorkplacePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const count = useAppSelector(workplaceSelector.count);
  const schedule = useAppSelector(workplaceSelector.schedule);
  const workplaces = useAppSelector(workplaceSelector.workplaces);
  const [showModal, setShowModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState<'delete' | 'save' | 'confirmToClose' | null>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(dataFilter[0].value);
  const [week, setWeek] = useState(0);
  const [mounts, setMounts] = useState<string[]>(['', '']);
  const [dates, setDates] = useState<Moment[]>([]);

  const [checkSearch, setCheckSearch] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const [isChanged, setChanged] = useState<boolean>(false);

  const updatePage = (type: 'create' | 'remove') => {
    switch (type) {
      case 'create': {
        const nextValue = Math.ceil(count / size);
        setPage(nextValue);
        break;
      }
      case 'remove':
        if (workplaces?.length === 1 && page !== 1) {
          setPage((prev) => prev - 1);
        } else {
          handledRequest();
        }
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const dates = getWeekDays(week);
    const startDate = dates[0];
    const endDate = moment(dates.at(-1));
    setMounts([startDate.toString(), endDate.toString()]);
    setDates(dates);
  }, [week]);

  const successDataWorkplace = (data: Record<string, any>[]) => {
    if (data?.length) {
      const ids = data.map((item) => item.id);
      const workplaceIds = ids.filter(Boolean).join(',');
      dispatch(
        workplaceSlice.actions.getScheduleRequest({
          workplace_ids: workplaceIds,
          group_by: 'workplace_id',
          group_by_sub: 'date_at',
          begin_at: formatDateToBack(mounts[0]),
          end_at: formatDateToBack(mounts[1]),
        }),
      );
    }
  };

  const handledRequest = useCallback(
    (search?: string) => {
      dispatch(
        workplaceSlice.actions.getAllWorkplaceRequest({
          payload: {
            data: {
              size,
              page,
              title: search || null,
            },
            success: (data: Record<string, any>[]) => {
              setCheckSearch(!!search);
              successDataWorkplace(data);
            },
          },
        }),
      );
    },
    [size, page, mounts[0]],
  );

  useEffect(() => {
    if (mounts[0].length) handledRequest();
  }, [size, page, mounts[0]]);

  const handleClick = (id: number) => () => {
    dispatch(
      workplaceSlice.actions.getWorkplaceIdRequest({
        payload: {
          data: { id },
          success: () => {
            setShowModal(true);
          },
        },
      }),
    );
  };
  const handleCloseModal = () => {
    dispatch(workplaceSlice.actions.resetData());
    setStateTabs?.((prev) => ({ ...prev, workplace: null }));
    setShowModal(false);
    setShowPopUp(null);
    setChanged(false);
  };

  const handledSuccess = (exit?: boolean) => {
    if (exit) {
      handleCloseModal();
    }
    handledRequest();
  };

  const handleClickSave = (exit?: boolean) => {
    const nextData = {
      ...stateTabs?.workplace?.data,
      image: isNewImage(stateTabs?.workplace?.data?.image),
      services: stateTabs?.workplace?.data?.services?.length
        ? stateTabs?.workplace?.data?.services?.map((i: Record<string, any>) => i.id).join(',')
        : 'null',
      workplaces: stateTabs?.workplace?.data?.workplaces?.length
        ? stateTabs?.workplace?.data?.workplaces?.map((i: Record<string, any>) => i.id).join(',')
        : null,
    };
    if (stateTabs?.workplace?.data?.id) {
      //UPDATE
      dispatch(
        workplaceSlice.actions.postUpdateWorkplaceRequest({
          payload: {
            data: nextData,
            success: () => handledSuccess(exit),
          },
        }),
      );
    } else {
      //CREATE
      dispatch(
        workplaceSlice.actions.postCreateWorkplaceRequest({
          payload: {
            data: nextData,
            success: () => handledSuccess(exit),
          },
        }),
      );
    }
    setShowPopUp(null);
  };
  const handleClickDelete = () => {
    if (stateTabs?.workplace?.data) {
      dispatch(
        workplaceSlice.actions.postDeleteWorkplaceRequest({
          payload: {
            data: { id: stateTabs?.workplace?.data?.id },
            success: () => {
              updatePage('remove');
            },
          },
        }),
      );
    }
    handleCloseModal();
  };

  const contentPopUp = {
    confirmToClose: {
      title: 'Закрыть окно?',
      onClick: () => handleCloseModal(),
    },
    save: {
      title: 'Сохранить изменения?',
      onClick: () => handleClickSave(),
    },
    delete: {
      title: 'Удалить рабочее место?',
      onClick: handleClickDelete,
    },
  };

  const buttons: ButtonPros[] = [
    {
      id: 1,
      type: 'icon',
      icon: IconCloud,
      onClick: () => console.log('print'),
    },
    {
      id: 2,
      type: 'icon',
      icon: IconPrinter,
      onClick: () => console.log('print'),
    },
    {
      id: 3,
      title: '+ Добавить',
      onClick: () => setShowModal(true),
    },
  ];

  const dataTabs: ItemTabs[] = [
    {
      label: 'О месте',
      content: <AboutPlace setChanged={setChanged} />,
    },
    /*{
      label: 'Расписание',
      content: <div style={{ minWidth: '980px' }}>Расписание</div>,
    },
    {
      label: 'Услуги',
      content: <Service />,
    },
    {
      label: 'Специалисты и филиалы',
      content: <div style={{ minWidth: '980px' }}>Специалисты и </div>,
    },
    {
      label: 'Документы',
      content: <div style={{ minWidth: '980px' }}>Документы</div>,
    },*/
  ];

  const handleClickFilter = (d: ItemFilter) => {
    setSize(+d.value);
  };
  const handleOnChange = (value: number) => {
    setWeek(value);
  };

  const handleClickButtonSave = (exit?: boolean) => () => {
    if (stateTabs?.workplace?.onIsValid?.()) {
      if (exit) {
        handleClickSave(exit);
      } else {
        setShowPopUp('save');
      }
    }
  };

  const handledClickTrash = () => {
    setShowPopUp('delete');
  };

  const handleClickPagination = (v: number) => {
    setPage(v);
  };
  const title = 'Новое рабочее место';

  const showValue = (search: string) => {
    if (mounts[0].length) {
      setCheckSearch(true);
      setPage(1);
      handledRequest(search);
    }
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), [mounts[0].length, showValue]);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    handledDebounce(e.target.value);
  };

  return (
    <>
      <Content>
        <ContentHeader>
          <Header title='Рабочее место' buttons={buttons} />
          {((workplaces && !!workplaces?.length) || checkSearch) && (
            <ControlBlock>
              <InputSearch title='Поиск' placeholder='Поиск' onChange={handledOnChangeSearch} value={search} />
              <GenerateDateWeek dateStart={mounts[0]} dateEnd={mounts[1]} onChange={handleOnChange} week={week} />
            </ControlBlock>
          )}
        </ContentHeader>
        {workplaces && !!workplaces?.length ? (
          <>
            <HeaderTable date={dates} />

            {workplaces?.map((workplace) => (
              <Row
                key={workplace?.id}
                data={schedule[workplace?.id.toString()]}
                dates={dates}
                workplace={<ItemWorkplace data={workplace} onClick={handleClick(workplace.id)} />}
              />
            ))}
            <Footer>
              <WrapperPagination>
                <PaginationBack size={size} count={count} page={page} onChange={handleClickPagination} />
              </WrapperPagination>
              <WrapperFilter>
                <NumberFiler title='По' data={dataFilter} onClick={handleClickFilter} />
              </WrapperFilter>
            </Footer>
          </>
        ) : checkSearch ? (
          <TextContent>Данные не найдены</TextContent>
        ) : (
          <TextContent>Данные отсутствуют</TextContent>
        )}
      </Content>

      <ModalAnt key={showModal?.toString()} show={showModal} onClose={() => isChanged ? setShowPopUp('confirmToClose') : handleCloseModal()}>
        <Tabs data={dataTabs} />
        <FooterBtn>
          <BLockBtn>
            <WrapperButton>
              <DefaultButton onClick={handleClickButtonSave()}>Сохранить</DefaultButton>
            </WrapperButton>
            <WrapperButton>
              <WhiteButton onClick={handleClickButtonSave(true)}>Сохранить и выйти</WhiteButton>
            </WrapperButton>
          </BLockBtn>
          {stateTabs?.workplace?.data?.id && (
            <WrapperIconButton>
              <IconButton onClick={handledClickTrash}>
                <IconTrash />
              </IconButton>
            </WrapperIconButton>
          )}
        </FooterBtn>
      </ModalAnt>

      {showPopUp && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </>
  );
};

export const Workplace: React.FC = (props: Record<string, any>) => {
  return (
    <TabsContextProvider>
      <WorkplacePage {...props} />
    </TabsContextProvider>
  );
};
const TextContent = styled.div`
  width: 100%;
  padding: 20px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;
const WrapperFilter = styled.div`
  margin-left: 3px;
`;
const WrapperPagination = styled.div`
  padding: 0 8px 0 25px;
  border-right: 1px solid var(--color-grey);
`;
const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 120px;
`;
const ControlBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;
const ContentHeader = styled.div`
  padding: 30px 30px 10px 30px;
`;
const Content = styled.div`
  width: 100%;
  padding-bottom: 80px;
`;
const FooterBtn = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;
const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
  }
`;
