import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { formatDateToBack, getWeekDays } from 'core/utils';
import { ButtonPros } from 'core/types';
import { IconCalendarFilter, IconLoopa, IconPrinter } from 'icons';
import { GenerateDateWeek } from 'features/generateDateWeek';
import { Sorting } from 'components/filters/sorting';
import { InputSearch } from 'components/inputSearch';
import { FilterSchedule } from 'components/filters/filterSchedule';
import { FilterDataProps } from 'components/filters/filter';
import { Table } from './table';
import moment, { Moment } from 'moment/moment';
import { initFilters } from './helpers';
import { dataSortingCalendar } from '../kanban/helpers';
import { SwitchText } from 'components/switchText';
import { ZoomFilter } from 'components/filters/zoom';
import { useAppDispatch } from '../../core/hooks';
import { scheduleSlice } from './scheduleSlice';
import { kanbanSlice } from '../kanban/kanbanSlice';
import { ModalChangeCardKanban } from '../../features/modals/modalChangeCardKanban';

export const Schedule: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState<FilterDataProps[]>(initFilters);
  const [week, setWeek] = useState(0);
  const [mounts, setMounts] = useState<string[]>(['', '']);
  const [dates, setDates] = useState<Moment[]>([]);
  const [switchValue, setSwitchValue] = useState(false);

  useEffect(() => {
    dispatch(kanbanSlice.actions.getAllColumnTodoListRequest());
  }, []);

  useEffect(() => {
    const dates = getWeekDays(week);
    const startDate = dates[0];
    const endDate = moment(dates.at(-1));
    setMounts([startDate.toString(), endDate.toString()]);
    setDates(dates);    
  }, [week]);

  const requestData = useCallback(() => {
    if (mounts[0].length) {
      dispatch(
        scheduleSlice.actions.getScheduleRequest({
          begin_at: formatDateToBack(mounts[0]),
          end_at: formatDateToBack(mounts[1]),
        }),
      );
    }
  }, [mounts]);

  useEffect(() => {
    const startDate = mounts[0]?.length ? mounts[0] : moment();
    const endDate = mounts[1]?.length ? mounts[1] : moment(startDate).add(1, 'M').endOf('month');
    const interval = setInterval(() => {
      dispatch(
        scheduleSlice.actions.getScheduleRequest({
          begin_at: formatDateToBack(startDate),
          end_at: formatDateToBack(endDate),
        }),
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    }
  }, [mounts]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const handleOnChange = (value: number) => {
    setWeek(value);
  };

  const buttons: ButtonPros[] = [
    /*{
      id: 1,
      type: 'icon',
      icon: IconPrinter,
      onClick: () => console.log('print'),
    },*/
    {
      id: 2,
      title: 'Добавить запись',
      onClick: () => setShowModal(true),
    },
  ];

  const handleClickOnChangeFilter = (d: FilterDataProps[]) => {
    setFilters(d);
  };

  return (
    <Content>
      <WrapperHeader>
        <Header
          title='Расписание'
          /*content={
            <SwitchText
              active={switchValue}
              onText={'по специалистам'}
              offText={'по рабочим местам'}
              onClick={() => setSwitchValue((prev) => !prev)}
            />
          }*/
          buttons={buttons}
        />
        <FiltersBlock>
          {/*<Filters>
            <WrapperFilter>
              <FilterSchedule data={filters} onChange={handleClickOnChangeFilter} />
            </WrapperFilter>
            <WrapperFilter>
              <ZoomFilter icon={IconLoopa} label={'Масштаб'} onChange={() => undefined} />
            </WrapperFilter>
            <WrapperFilter>
              <Sorting
                icon={IconCalendarFilter}
                label={'Неделя'}
                data={dataSortingCalendar}
                onChange={() => undefined}
              />
            </WrapperFilter>
            <WrapperFilter>
              <InputSearch title='Поиск' placeholder='Поиск' />
            </WrapperFilter>
          </Filters>*/}
          <GenerateDateWeek dateStart={mounts[0]} dateEnd={mounts[1]} onChange={handleOnChange} week={week} />
        </FiltersBlock>
      </WrapperHeader>
      <Table updateData={requestData} dates={dates} />
      <ModalChangeCardKanban showModal={showModal} setShowModal={setShowModal} updateData={requestData} />
    </Content>
  );
};
const WrapperRange = styled.div`
  width: 100px;
`;
const WrapperFilter = styled.div`
  margin-left: 3px;
`;
const Filters = styled.div`
  display: flex;
  align-items: center;
`;
const FiltersBlock = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperHeader = styled.div`
  padding: 24px;
`;
const Content = styled.div`
  width: 100%;
`;
