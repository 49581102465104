import React, { useEffect, useMemo, useState } from 'react';
import { BaseTimePicker } from './base';
import dayjs, { Dayjs } from 'dayjs';
import styled, { css } from 'styled-components';

type TimePickerProps = {
  valid?: boolean;
  title?: string;
  value?: string;
  height?: number;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (v?: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const TimePicker: React.FC<TimePickerProps> = ({
  title,
  valid = true,
  value,
  placeholder = '__:__',
  onBlur,
  onFocus,
  onChange,
  height = 40,
  disabled,
}) => {
  const [valueTime, setValueTime] = useState<Dayjs | null>();
  const [styleTitle, setStyleTitle] = useState(false);
  const [bluerTitle, setBluerTitle] = useState(true);

  const status = useMemo(() => (!valid ? 'error' : ''), [valid]);

  useEffect(() => {
    setValueTime(value ? dayjs(value, 'HH:mm') : undefined);
  }, [value]);

  const handleOnChange = (time: Dayjs | null) => {
    setValueTime(time ? dayjs(time, 'HH:mm') : undefined);
    onChange?.(time ? dayjs(time, 'HH:mm').format('HH:mm') : undefined);
  };

  const handleOnFocus = () => {
    onFocus?.();
    if (title) {
      setStyleTitle(true);
      setBluerTitle(false);
    }
  };
  const handleOnBluer = () => {
    onBlur?.();
    if (title) {
      setStyleTitle(false);
      setBluerTitle(true);
    }
  };

  return (
    <Wrapper height={height} disabled={disabled}>
      <BaseTimePicker
        placeholder={placeholder}
        status={status}
        value={valueTime}
        onChange={handleOnChange}
        onBlur={handleOnBluer}
        onFocus={handleOnFocus}
        disabled={disabled || false}
      />
      {title && (
        <Title styleTitle={styleTitle || !!value} bluerTitle={bluerTitle}>
          {title}
        </Title>
      )}
    </Wrapper>
  );
};

const Title = styled.span<{ styleTitle?: boolean; bluerTitle?: boolean }>`
  color: var(--color-black-grey);
  background: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.33s ease;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  ${({ styleTitle, bluerTitle }) => {
    if (styleTitle) {
      return css`
        transform: translateY(0);
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        top: -6px;
        background: var(--color-white);
        padding: 0 5px;
        color: ${bluerTitle ? 'var(--color-black-grey)' : '#3562d4'};
      `;
    } else return null;
  }}
`;
const Wrapper = styled.div<{ height?: number, disabled?: boolean }>`
  position: relative;
  ${({ height }) => {
    return css`
      .ant-picker {
        height: ${height}px;
      }
    `;
  }};
  ${({ disabled }) => {
    if (disabled === true) {
      return css`
        .ant-picker {
          background-color: var(--color-white) !important;
          cursor: unset !important;
        }
        .ant-picker-disabled input {
          color: var(--color-black) !important;
          background-color: var(--color-white) !important;
          cursor: unset !important;
        }
      `;
    }
  }};
`;
