import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { IconChecked } from '../../icons';

type CheckboxProps = {
  disabled?: boolean;
  title?: string;
  active: boolean;
  setActive: () => void;
  color?: string;
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { color = '#6471E0' } = props;
  return (
    <WrapperContent onClick={!props?.disabled ? props.setActive : () => undefined}>
      <Content disabled={props.disabled} color={color}>
        {props.active && <Icon />}
      </Content>
      {!!props.title && <Title>{props.title}</Title>}
    </WrapperContent>
  );
};
const Icon = styled(IconChecked)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  margin-left: 5px;
  white-space: nowrap;
`;

const WrapperContent = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div<{ color: string; disabled?: boolean }>`
  position: relative;
  height: 14px;
  width: 14px;
  border: ${({ color, disabled }) => (disabled ? '1px solid var(--color-grey)' : `1px solid ${color}`)};
  border-radius: 3px;
  user-select: none;
`;
